import { DefaultTheme } from "styled-components";

interface MyTheme extends DefaultTheme {
    color: {
        mainBrand: string,
        secondBrand: string,
        black: string,
        white: string,
    },
    fontSize: {
        tiny: string,
        base: string,
        normal: string,
        medium: string,
        large: string,
        big: string,
        huge: string,
        gigantic: string
    },
    fontWeight: {
        thin: string,
        light: string,
        bold: string,
        extraBold: string
    },
    breakPoints: {
        desktop: string,
        tablet: string,
        mobile: string
    },
    fontFamily: {
        poppinsRegular: string,
        poppinsBold: string
    }
}

const myTheme: MyTheme = {
    color: {
        mainBrand: "#F07313",
        secondBrand: "#21375D",
        black: "#111111",
        white: "#FFFFFF",
    },
    fontSize: {
        tiny: "14px",
        base: "16px", 
        normal: "24px",
        medium: "28px",
        large: "32px",
        big: "40px",
        huge: "48px",
        gigantic: "64px" 
    },
    fontWeight: {
        thin: "400",
        light: "500",
        bold: "600",
        extraBold: "700"
    },
    breakPoints: {
        desktop: "1200px",
        tablet: "768px",
        mobile: "500px"
    },
    fontFamily: {
        poppinsRegular: "Poppins-Regular",
        poppinsBold: "Poppins-Bold",
    }
};

export { myTheme };
